import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

const SeoComponent = ({
    title,
    description,
    keywords,
    image,
    url,
    author,
    language,
}) => {
    console.log(
        title,
        description,
        keywords,
        image,
        url,
        author,
        language,
        '===helmet'
    )
    return (
        <StaticQuery
            query={detailsQuery}
            render={data => {
                const MetaTitle = title || data.site.siteMetadata.title
                const MetaDescription =
                    description || data.site.siteMetadata.description
                const MetaImage = image || data.site.siteMetadata.imageUrl
                const MetaUrl = url || data.site.siteMetadata.url
                const MetaAuthor = author || data.site.siteMetadata.author
                const MetaKeywords = keywords || ['ableaura', 'gatsby ableaura']

                return (
                    <Helmet
                        title={title}
                        htmlAttributes={{ lang: 'en' }}
                        meta={[
                            {
                                name: `description`,
                                content: MetaDescription,
                            },
                            {
                                property: `og:title`,
                                content: MetaTitle,
                            },
                            {
                                property: `og:description`,
                                content: MetaDescription,
                            },
                            {
                                property: `og:type`,
                                content: `website`,
                            },
                            {
                                property: `og:image`,
                                content: MetaImage,
                            },
                            {
                                property: `og:url`,
                                content: MetaUrl,
                            },
                            {
                                name: `twitter:card`,
                                content: `large_image`,
                            },
                            {
                                name: `twitter:creator`,
                                content: MetaAuthor,
                            },
                            {
                                name: `twitter:title`,
                                content: MetaTitle,
                            },
                            {
                                name: `twitter:description`,
                                content: MetaDescription,
                            },
                            {
                                name: `twitter:image`,
                                content: MetaImage,
                            },
                        ].concat(
                            MetaKeywords && MetaKeywords.length > 0
                                ? {
                                    name: `keywords`,
                                    content: MetaKeywords.join(`, `),
                                }
                                : []
                        )}
                    />
                )
            }}
        />
    )
}

const detailsQuery = graphql`
  query DefaultSeoQuery {
    site {
      siteMetadata {
        title
        description
        author
        url
        imageUrl
      }
    }
  }
`
export default SeoComponent
