import * as React from "react"
import { Spinner } from "react-bootstrap"

// const pageStyles = {
//   color: "#232129",
//   padding: "96px",
//   fontFamily: "-apple-system, Roboto, sans-serif, serif",
// }
// const headingStyles = {
//   marginTop: 0,
//   marginBottom: 64,
//   maxWidth: 320,
// }

// const paragraphStyles = {
//   marginBottom: 48,
// }
// const codeStyles = {
//   color: "#8A6534",
//   padding: 4,
//   backgroundColor: "#FFF4DB",
//   fontSize: "1.25rem",
//   borderRadius: 4,
// }

const NotFoundPage = () => {

  const [isLoading, setisLoading] = React.useState(true);


  React.useEffect(() => {
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <div className="clearfix" />
      <div className="mainsec">
        <div className="notFoundsec">
          <div className="clearfix"></div>
          <div className="mainsec">
            <div className="loadingSec">
              {/* Not found */}
              <Spinner animation="border" variant="dark" />{' '}
            </div>
          </div>
        </div>
      </div>
      {/* <main>
        <h1 style={{ textAlign: "center", marginTop: "60px" }}>Page not found</h1>
        <p style={{ textAlign: "center", marginBottom: "60px" }}>Sorry, we couldn't find what you were looking for.</p>
      </main> */}
    </>
  )
}

export default NotFoundPage

// export const Head = () => <title>Not found</title>
